import { camelCase, isEmpty } from 'lodash';
import { routesMapping } from '@pages/routes';

export default function editPath({
  applicationId, id, entityTypeKey, query, ...rest
}) {
  const entityRoutes = routesMapping[camelCase(entityTypeKey)];
  if (isEmpty(entityRoutes)) {
    console.error('Missing routes type for type', entityTypeKey);
    return null;
  }

  return entityRoutes.editPath({
    applicationId,
    id,
    ...rest,
    query,
  });
}
