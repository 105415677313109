import fetchAppData from '@services/fetchAppData';
import { redirectDocument } from 'react-router-dom';

import editPath from '@services/editPath';

const sourceURL = import.meta.resolve('/');
async function loader({ request }) {
  const url = new URL(request.url);
  const entityKey = url.searchParams.get('entityKey');
  const id = url.searchParams.get('id');
  const appId = url.searchParams.get('applicationId');
  const { applications } = await fetchAppData();
  const applicationId = applications.find((app) => app.app_id === appId).id;
  const targetUrl = new URL(editPath({ applicationId, entityTypeKey: entityKey, id }), sourceURL);
  return redirectDocument(targetUrl);
}

/** @type import('react-router-dom').RouteObject[] */
const routes = [
  {
    path: 'admin/open_entity',
    loader,
  },
];

export default routes;
