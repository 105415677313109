import React from 'react';
import { some } from 'lodash';

import { useCurrentApplication } from '@hooks';
import editPath from '@services/editPath';
import { useRouter } from '@tripledotstudios/react-core';

import InUseLink from '@components/in_use/InUseLink';
import { AutoCollapse } from '../PartialCollapse';

export const InUseCollapse = ({ inUse }) => (
  <AutoCollapse minHeight={105}>
    <div className="d-flex flex-column">
      <InUseLinks inUse={inUse} />
    </div>
  </AutoCollapse>
);

export const isInUse = (inUse) => some(inUse, (subsets) => Array.isArray(subsets) && subsets.length > 0);

export const InUseText = ({ inUse }) => (
  Object.keys(inUse).map((inUseKey) => inUse[inUseKey].map((entity) => entity.name).join(', ')).join(';')
);

export const InUseLinks = ({ inUse }) => {
  const { query } = useRouter();
  const application = useCurrentApplication();

  return inUse && Object.keys(inUse).map(
    (inUseKey) => inUse[inUseKey].map((entity) => {
      const path = editPath({ ...entity, applicationId: application.id, entityTypeKey: inUseKey });
      return <InUseLink path={path} {...query} {...entity} />;
    }),
  );
};
