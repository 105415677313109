import React, { useState } from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import { FormGroup } from '@tripledotstudios/react-core';

import { useWatch } from 'react-hook-form';
import {
  useAppData, useFormPermissions, useCurrentApplication, useSortableFieldArray,
} from '@hooks';
import { DragContext, DragAndDroppable, DragHandle } from '@components/dnd';
import { Form, Button } from '@components';
import { BarsIcon } from '@components/icons';
import {
  Input, Select, RegisteredError, SwitchSimple,
} from '@components/resource';

import useProductsOptions from '@pages/payments/queries';
import useStoreProductsOptions from '@pages/payments/store_products/queries';

const gameProductsPriceTypes = ['store_product', 'ads', 'free'];

const Position = ({ itemPath }) => useWatch({ name: `${itemPath}.position` });

const DraggableHandle = ({ attributes, listeners }) => (
  <td>
    <DragHandle attributes={attributes} listeners={listeners}>
      <BarsIcon />
    </DragHandle>
  </td>
);

export default function SequentialOfferItems({ inUse }) {
  const { readOnly } = useFormPermissions();
  const [dndEnabled, setDndEnabled] = useState(false);
  const editable = !readOnly && !inUse;

  const currentApplication = useCurrentApplication();

  const priceTypesEnum = useAppData().enums['Payments::PriceTypes'];
  const priceTypes = useAppData().enumOptions['Payments::PriceTypes'].filter((priceType) => (
    gameProductsPriceTypes.includes(priceType.value)
  ));

  const productsQuery = useProductsOptions();
  const storeProductsQuery = useStoreProductsOptions({ applicationId: currentApplication.id });

  const formGroupName = 'sequentialOfferItemsAttributes';
  const {
    fields,
    append,
    handleDelete,
    handleDragEnd,
  } = useSortableFieldArray({ name: formGroupName, keyName: 'key' });

  const appendItem = () => append({ priceType: priceTypesEnum.STORE_PRODUCT });

  const isSomeLoading = [productsQuery, storeProductsQuery].some(({ isLoading }) => isLoading);
  if (isSomeLoading) return null;

  const dndItems = fields.filter(({ _destroy }) => !_destroy).map((field) => field.key);

  return (
    <>
      {editable && (
        <Row>
          <Col>
            <SwitchSimple
              className="float-start mb-2"
              checked={dndEnabled}
              value="1"
              label="Reorder"
              onChange={(e) => setDndEnabled(e.currentTarget.checked)}
            />
          </Col>
        </Row>
      )}
      <RegisteredError name="base" />
      {dndItems.length > 0 && (
        <DragContext onDragEnd={handleDragEnd} items={dndItems}>
          <Table size="sm">
            <thead>
              <tr>
                {dndEnabled && <th />}
                <th>Position</th>
                <th>Game Product</th>
                <th>Price Type</th>
                <th>Price</th>
                {editable && <th width="10%">Action</th>}
              </tr>
            </thead>
            <tbody>
              {fields.map((item, index) => {
                if (item._destroy) return null;

                const itemPath = `${formGroupName}.${index}`;
                const fieldDisabled = inUse && item.id;

                return (
                  <DragAndDroppable
                    tag="tr"
                    id={item.key}
                    key={item.key}
                    disabled={!dndEnabled}
                    dragHandleRender={dndEnabled ? DraggableHandle : null}
                  >
                    <FormGroup name={itemPath}>
                      <td>
                        <Input hidden name="position" readOnly disabled type="number" />
                        <Position itemPath={itemPath} />
                      </td>
                      <td>
                        <Select
                          name="productId"
                          disabled={fieldDisabled}
                          options={productsQuery.data}
                          isSearchable
                        />
                      </td>
                      <td>
                        <Select name="priceType" disabled={fieldDisabled} options={priceTypes} />
                      </td>
                      <td>
                        <Form.When name="priceType" value={priceTypesEnum.STORE_PRODUCT}>
                          <Select
                            name="storeProductId"
                            disabled={fieldDisabled}
                            options={storeProductsQuery.data}
                            isSearchable
                          />
                        </Form.When>
                      </td>
                      {!fieldDisabled && (
                        <td><Button.Delete title="Remove" onClick={() => handleDelete(item, index)} /></td>
                      )}
                    </FormGroup>
                  </DragAndDroppable>
                );
              })}
            </tbody>
          </Table>
        </DragContext>
      )}
      {editable && <Button.Add title="Add item" onClick={appendItem} />}
    </>
  );
}
