import { useOptionsQuery } from '@hooks';
import { EventsRoutes } from '@pages/routes';

export default function useEventsCollectionSetOptions() {
  return useOptionsQuery({
    name: 'events/collectionSets',
    request: EventsRoutes.CollectionSets.listOptionsRequest,
    transform: (data) => data,
  });
}
