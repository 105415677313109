import {
  isArray, isPlainObject, camelCase, snakeCase,
} from 'lodash';
import qs from 'qs';

import axios from './axios';
import { clearAuthData } from './auth';
import renderPlainAlert from './plain_alert';

const baseHost = '';

axios.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.paramsSerializer = {
    serialize: (params) => (
      qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: true,
      })),
  };

  return config;
});

function processRecursively(data, fn, exclude = []) {
  if (isPlainObject(data)) {
    const obj = {};
    Object.keys(data).forEach((key) => {
      const resultKey = exclude.includes(key) ? key : fn(key);

      obj[resultKey] = processRecursively(data[key], fn, exclude);
    });
    return obj;
  }

  if (isArray(data)) {
    return data.map((i) => processRecursively(i, fn, exclude));
  }

  return data;
}

function withDefaultData(options) {
  return { ...options, data: options.data || {} };
}

function handleError(error, { showAlert = true } = {}) {
  if (error.response.status === 401) {
    clearAuthData();
    window.location = '/admin/sign_in?session_expired=1';
  }

  if (error.response.status === 403) {
    if (showAlert) {
      renderPlainAlert({ message: error.response.data.reason });
    }

    throw new Error('Access denied');
  }

  return error.response;
}

function prepareInboundData(data) {
  return processRecursively(data, camelCase, ['_destroy', '_meta', '_uuid']);
}

function processInbound(request, options = {}) {
  return request.catch((error) => handleError(error, options)).then((response) => (
    {
      data: options.skipInboundProcessing ? response.data : prepareInboundData(response.data),
      status: response.status,
    }
  ));
}

function prepareOutboundData(data) {
  return processRecursively(data, snakeCase, ['_destroy', '_meta', '_uuid']);
}

export function get(url, options = {}) {
  return processInbound(axios.get(`${baseHost}${url}`,
    prepareOutboundData(withDefaultData(options))), options);
}

export function patch(url, data, options = {}) {
  return processInbound(axios.patch(`${baseHost}${url}`,
    prepareOutboundData(data),
    withDefaultData(options)));
}

export function put(url, data, options = {}) {
  return processInbound(axios.put(`${baseHost}${url}`, prepareOutboundData(data), withDefaultData(options)));
}

export function post(url, data, options = {}) {
  return processInbound(axios.post(`${baseHost}${url}`, prepareOutboundData(data), withDefaultData(options)));
}

export function destroy(url, options = {}) {
  return processInbound(axios.delete(`${baseHost}${url}`, options));
}

export function fetchCollection(url, params = {}, options = {}) {
  return get(url, { ...options, params });
}

export function newResource(baseUrl, options = {}) {
  return get(`${baseUrl}/new`, options);
}

export function editResource(baseUrl, data, options = {}) {
  return get(`${baseUrl}/${data.id}/edit`, options);
}

export function getResource(baseUrl, data, options = {}) {
  return get(`${baseUrl}/${data.id}`, options);
}

export function createResource(url, data, options = {}) {
  return post(url, data, options);
}

export function updateResource(baseUrl, data, options = {}) {
  return put(`${baseUrl}/${data.id}`, data, options);
}

export function deleteResource(baseUrl, data, options = {}) {
  return destroy(`${baseUrl}/${data.id}`, options);
}
