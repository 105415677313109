import React, { useMemo } from 'react';
import { Table, EmptyList } from '@components/collection';
import { InUse, Button } from '@components';
import { ShowIcon } from '@components/icons';

export default function GameProductsList({
  response, onEditButtonClick, selectedResourceId, onDelete, routes,
}) {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Display Name Key',
        accessor: 'displayNameKeyName',
      },
      {
        Header: 'Updated at (UTC)',
        accessor: 'updatedAt',
      },
      InUse.InUseColumn,
      {
        Header: 'Actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: {
              id, name, permissions, inUse,
            },
          },
        }) => {
          if (!permissions.edit) {
            return null;
          }
          const options = !permissions.update ? { title: 'Show', icon: <ShowIcon /> } : {};
          const isInUse = InUse.isInUse(inUse);

          return (
            <>
              <Button.Edit {...options} className="me-2" onClick={() => onEditButtonClick(id)} />
              {!isInUse && permissions?.destroy && (
                <Button.DeleteConfirm
                  id={id}
                  routes={routes}
                  onDelete={onDelete}
                  recordName={`Game Product "${name}"`}
                />
              )}
            </>
          );
        },
      },
    ],
    [],
  );

  if (!response || response.items.length < 1) {
    return <EmptyList title="No game products yet." />;
  }

  return (
    <Table columns={columns} selectedRowId={selectedResourceId} data={response.items} />
  );
}
