import { useOptionsQuery } from '@hooks';
import { PaymentsRoutes } from '@pages/routes';

export default function useProductsOptions() {
  return useOptionsQuery({
    name: 'payments/products',
    request: PaymentsRoutes.Products.listOptionsRequest,
    transform: (data) => data,
  });
}
