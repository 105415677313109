import {
  useContext,
  createContext,
  useState,
  useEffect,
} from 'react';
import fetchAppData from '@services/fetchAppData';

const AppDataContext = createContext({});

const ProvideAppData = ({ children }) => {
  const [data, setData] = useState();

  let loadData;
  const processResponse = (response) => setData({ ...response, reload: loadData });

  loadData = () => fetchAppData().then(processResponse);

  useEffect(() => { loadData(); }, []);

  return (
    <AppDataContext.Provider value={data}>
      {data && children(data)}
    </AppDataContext.Provider>
  );
};

const useAppData = () => useContext(AppDataContext);

export {
  useAppData,
  ProvideAppData,
};
