import { mapValues, values, sortBy } from 'lodash';

import { SystemRoutes as Routes } from '@pages/routes';

/** @typedef {{ [Key in keyof RawAppEnums]: { [Option in keyof RawAppEnums[Key]]: string } }} AppLocalizedEnums */
/**
 * @typedef {Object} AppData
 * @property {AppEnums} enums
 * @property {AppLocalizedEnums} localizedEnums
 * @property {AppI18n} i18n
 * @property {AppFeatureToggles} featureToggles
 * @property {AppEnumsOptions} enumOptions
 * @property {RawAppPermissions} permissions
 */

/** @type {import('react').Context<Record<string, any> & AppData>} */

const shortEnvironmentNameMap = {
  development: 'DEV',
  feature: 'FB',
  staging: 'STG',
  production: 'PRD',
};

const presentAppData = (rawData) => {
  const enumsToOptions = (enums) => mapValues(enums, (klassEnum) => (
    values(klassEnum).map(({ value, translation: label }) => ({ value, label }))
  ));

  return {
    enums: mapValues(rawData.enums, (klassEnum) => mapValues(klassEnum, 'value')),
    localizedEnums: mapValues(rawData.enums, (klassEnum) => mapValues(klassEnum, 'translation')),
    environment: rawData.environment,
    shortEnvironment: shortEnvironmentNameMap[rawData.environment],
    i18n: rawData.i18n,
    featureToggles: rawData.feature_toggles,
    enumOptions: enumsToOptions(rawData.enums),
    applications: sortBy(rawData.applications, ['name']),
    permissions: rawData.permissions,
  };
};

export default function fetchAppData() {
  return Routes.appDataRequest({}, { skipInboundProcessing: true }).then((response) => presentAppData(response.data));
}
