import React from 'react';

import { useAppData, useCurrentApplication } from '@hooks';
import { Form } from '@components';

import {
  FloatingLabeledSelect, FloatingLabeledInput, InputWithAddon, DiffWrapper,
} from '@components/resource';

import useProductsOptions from '@pages/payments/queries';
import useStoreProductsOptions from '@pages/payments/store_products/queries';
import { useProductLabelsOptions } from '@pages/payments/product_labels/queries';

const gameProductsPriceTypes = ['store_product', 'in_game_currency', 'ads', 'free'];
const gameProductsSaleTypes = ['none', 'more_value', 'price_cut', 'extra_amount'];

const cashProductsPriceTypes = ['cash'];
const cashProductsSaleTypes = ['none', 'extra_amount'];

export default function CreativePersonalOffer() {
  const currentApplication = useCurrentApplication();
  const productTypes = useAppData().enums.ApplicationProductsTypes;

  const isGameProductsApplication = currentApplication.productsType === productTypes.GAME_PRODUCTS;
  const priceTypesEnum = useAppData().enums['Payments::PriceTypes'];
  const priceTypes = useAppData().enumOptions['Payments::PriceTypes'].filter((priceType) => (
    (isGameProductsApplication ? gameProductsPriceTypes : cashProductsPriceTypes).includes(priceType.value)
  ));
  const roundTypes = useAppData().enumOptions['Payments::RoundingTypes'];
  const saleTypesEnum = useAppData().enums['Payments::CatalogProductSaleTypes'];
  const saleTypes = useAppData().enumOptions['Payments::CatalogProductSaleTypes'].filter((priceType) => (
    (isGameProductsApplication ? gameProductsSaleTypes : cashProductsSaleTypes).includes(priceType.value)
  ));

  const productsQuery = useProductsOptions();
  const storeProductsQuery = useStoreProductsOptions();
  const productLabelsQuery = useProductLabelsOptions({ applicationId: currentApplication.id });

  const isSomeLoading = [productsQuery, storeProductsQuery, productLabelsQuery].some(({ isLoading }) => isLoading);

  if (isSomeLoading) return null;

  return (
    <>
      <DiffWrapper for="priceType">
        <FloatingLabeledSelect label="Price type" name="priceType" options={priceTypes} />
      </DiffWrapper>

      <DiffWrapper for="productId">
        <FloatingLabeledSelect label="Product" name="productId" options={productsQuery.data} isSearchable />
      </DiffWrapper>

      <Form.When name="priceType" value={priceTypesEnum.STORE_PRODUCT}>
        <DiffWrapper for="storeProductId">
          <FloatingLabeledSelect label="Price" name="storeProductId" options={storeProductsQuery.data} isSearchable />
        </DiffWrapper>
      </Form.When>
      <Form.When name="priceType" value={priceTypesEnum.IN_GAME_CURRENCY}>
        <DiffWrapper for="inGameValue">
          <FloatingLabeledInput label="Price" name="inGameValue" />
        </DiffWrapper>
      </Form.When>

      <DiffWrapper for="labelId">
        <FloatingLabeledSelect label="Label" name="labelId" options={productLabelsQuery.data} />
      </DiffWrapper>

      <Form.When
        name="priceType"
        value={(priceType) => ![priceTypesEnum.ADS, priceTypesEnum.FREE].includes(priceType)}
      >
        <DiffWrapper for="saleType">
          <FloatingLabeledSelect label="Sale type" name="saleType" options={saleTypes} />
        </DiffWrapper>

        <Form.When name="saleType" value={saleTypesEnum.MORE_VALUE}>
          <DiffWrapper for="moreValue">
            <InputWithAddon
              name="moreValue"
              after="% more"
              type="number"
              style={{ minHeight: '42px' }}
            />
          </DiffWrapper>
          <DiffWrapper for="roundingType">
            <FloatingLabeledSelect label="Rouding type" name="roundingType" options={roundTypes} />
          </DiffWrapper>
        </Form.When>
        <Form.When name="saleType" value={saleTypesEnum.EXTRA_AMOUNT}>
          <DiffWrapper for="extraAmount">
            <InputWithAddon
              name="extraAmount"
              after="extra"
              step="0.01"
              type="number"
              style={{ minHeight: '42px' }}
            />
          </DiffWrapper>
        </Form.When>
        <Form.When name="saleType" value={saleTypesEnum.PRICE_CUT}>
          <Form.When name="priceType" value={priceTypesEnum.IN_GAME_CURRENCY}>
            <DiffWrapper for="saleValue">
              <FloatingLabeledInput label="Sale Price" name="saleValue" />
            </DiffWrapper>
          </Form.When>
          <Form.When name="priceType" value={priceTypesEnum.STORE_PRODUCT}>
            <DiffWrapper for="saleStoreProductId">
              <FloatingLabeledSelect
                label="Sale Price"
                name="saleStoreProductId"
                options={storeProductsQuery.data}
                isSearchable
              />
            </DiffWrapper>
          </Form.When>
        </Form.When>
      </Form.When>
    </>
  );
}
