import { useId } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from '@tripledotstudios/react-core';
import { at } from 'lodash';

export const DEFAULT_LOADER_STALE_TIME = 1000;
const listQueryParameterNames = ['page', 'per', 'filter'];

const buildQuery = ({
  request, queryKey, transform, params = {}, staleTime = DEFAULT_LOADER_STALE_TIME, ...opts
}) => ({
  queryKey,
  queryFn: async () => {
    const { data } = await request(params);
    if (transform) {
      return transform(data);
    }

    return data;
  },
  staleTime,
  ...opts,
});

export function useListQuery({ name, queryKey, ...opts }) {
  const { query } = useRouter();
  const listQueryParams = at(query, listQueryParameterNames);
  const internalId = useId();
  const key = name ? [name || internalId, listQueryParams] : queryKey;

  return useQuery(buildQuery({
    queryKey: key,
    params: { ...query, includeInUse: true, withoutPagination: true },
    ...opts,
  }));
}

export function useOptionsQuery({
  name, queryKey, params, ...opts
}) {
  const { query: routerQuery } = useRouter();
  const query = params || routerQuery;

  return useQuery(buildQuery({
    queryKey: queryKey || [name, 'listOptions', query.applicationId],
    params: query,
    ...opts,
  }));
}
