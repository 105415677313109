import React from 'react';
import { PageSection, Alert } from '@tripledotstudios/react-core';

import { Form } from '@components';
import { StyledPane } from '@components/SplitPane';
import { ButtonsFooter } from '@components/collection';
import { LabeledInput, LabeledSelect, LabeledSwitch } from '@components/resource';
import { buildResourceResponseHandler, redirectToPath } from '@services/response_handler';
import { ApplicationsRoutes as Routes } from '@pages/routes';
import { useAppData } from '@hooks';
import ApplicationTokensForm from './ApplicationTokensForm';

export default function ApplicationForm({
  defaultValues, actionName, submitRequest,
}) {
  const productsTypesOptions = useAppData().enumOptions.ApplicationProductsTypes;
  const statusOptions = useAppData().enumOptions.ApplicationStatuses;
  const productTypes = useAppData().enums.ApplicationProductsTypes;
  const { applications, reload: reloadAppData } = useAppData();
  const applicationsOptions = [
    { label: 'None', value: '' },
    ...applications.map(({ id, name }) => ({ label: `${name} (ID: ${id})`, value: String(id) })),
  ];

  const isEdit = actionName === 'update';

  return (
    <>
      <StyledPane>
        <Form defaultValues={defaultValues}>
          {({ setError, watch }) => {
            const redirectToIndex = redirectToPath(Routes.indexPath);
            const responseHandler = buildResourceResponseHandler({
              actionName,
              setError,
              onSuccess: () => {
                reloadAppData();
                redirectToIndex();
              },
            });
            const { managedRemotely } = defaultValues;
            const onSubmit = (values) => submitRequest(values).then(responseHandler);

            const isGameProductsApplication = watch('productsType') === productTypes?.GAME_PRODUCTS;

            return (
              <Form.Component onSubmit={onSubmit}>
                {managedRemotely && (
                  <Alert variant="info">
                    <span>This application is managed via </span>
                    <a href="https://app-server.tripledotapi.com">App Server</a>
                    <span>, some fields are not editable here</span>
                  </Alert>
                )}
                <PageSection title="General Settings">
                  <LabeledInput label="Name" name="name" disabled={managedRemotely} />
                  <LabeledInput label="App ID" name="appId" disabled={managedRemotely} />
                  <LabeledSelect label="Status" name="status" options={statusOptions} />
                  <LabeledInput label="Bundle ID (Deprecated)" name="bundleId" />
                  <LabeledInput label="App Link Schema" name="appLinkSchema" />
                  <LabeledInput label="User Data URL" name="userDataUrl" />
                  <LabeledInput label="External Attributes S3 filepath" name="externalAttributesFilepath" />
                  <LabeledSwitch label="Ingame Events Enabled" name="ingameEventsEnabled" />
                  <LabeledSwitch label="Localisations Support" name="localisationsSupport" />
                </PageSection>
                <PageSection title="Products Settings">
                  <LabeledSelect
                    label="Products type"
                    name="productsType"
                    options={productsTypesOptions}
                    disabled={defaultValues.id}
                  />
                  <LabeledInput label="Game Items URL" name="gameItemsUrl" />
                  {isGameProductsApplication && (
                    <>
                      <LabeledInput label="Store Product Application key" name="storeProductKey" />
                      <LabeledInput label="Product Updates URL" name="productUpdatesUrl" />
                    </>
                  )}
                </PageSection>
                <PageSection title="Events Settings">
                  <LabeledSwitch label="Objective settings on goal level" name="objectiveGoalParamsEnabled" />
                </PageSection>
                <PageSection title="Asset Bundles">
                  <LabeledInput label="Asset Bundles URL" name="assetBundlesUrl" />
                </PageSection>
                <PageSection title="Campaigns Settings">
                  <LabeledInput
                    label="Max # of creatives returned to player"
                    name="impressionsCap"
                  />
                  <LabeledInput
                    label="Max # of lasting creatives returned to player"
                    name="maxLastingCreatives"
                    tooltipText="0 means unlimited number"
                  />
                  <LabeledSwitch label="Players cards in campaigns" name="playerCardsEnabled" />
                </PageSection>
                <PageSection title="Gacha">
                  <LabeledInput label="Item rarity count" name="itemRarities" disabled={isEdit} readonly={isEdit} />
                  <LabeledInput label="Tier count" name="tiers" disabled={isEdit} readonly={isEdit} />
                </PageSection>
                <PageSection title="JWT Secrets">
                  {defaultValues.jwtSecrets?.length > 0 && defaultValues.jwtSecrets.map((_, i) => (
                    <LabeledInput label={`Secret ${i + 1}`} name={`jwtSecrets[${i}]`} disabled />
                  ))}
                </PageSection>
                <PageSection title="Dev Settings">
                  <LabeledInput label="Player Service token" name="playerServerToken" disabled />
                  <LabeledSelect
                    label="Migrate players from another application"
                    name="migrateFromAppId"
                    tooltipText="players will be migrated while first API request with new App ID from this application"
                    options={applicationsOptions}
                  />
                </PageSection>
                {defaultValues.id && <ApplicationTokensForm />}
              </Form.Component>
            );
          }}
        </Form>
      </StyledPane>
      <ButtonsFooter
        cancelOptions={{ asLink: true, routes: Routes }}
        submitOptions={{ disabled: !defaultValues.permissions.update }}
      />
    </>
  );
}
