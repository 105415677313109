import React from 'react';
import { Table, Row, Col } from 'react-bootstrap';

import { PageSection, useRouter } from '@tripledotstudios/react-core';
import { useQuery } from '@hooks';
import { Status } from '@components/collection';
import InUseLink from '@components/in_use/InUseLink';
import { AccountsRoutes } from '@pages/routes';
import editPath from '@services/editPath';

export default function ExperimentParticipationsView() {
  const { query } = useRouter();
  const { applicationId, id } = query;
  const { response } = useQuery(AccountsRoutes.PlayerPresets.indexRequest, { userId: id, applicationId });

  const playerPresets = response?.items;

  return playerPresets && (
    <>
      <PageSection title="Player Presets" />

      <Row>
        <Col xs={12}>
          <Table className="table-bordered table-striped">
            <thead>
              <tr>
                <th>Started At</th>
                <th>End At</th>
                <th>Status</th>
                <th>Preset</th>
                <th>Campaign with segment</th>
                <th>Segment ID for the Catalog</th>
              </tr>
            </thead>
            <tbody>
              {playerPresets.map((playerPreset) => (
                <tr key={playerPreset.id}>
                  <td>{playerPreset.createdAt}</td>
                  <td>{playerPreset.endAt}</td>
                  <td><Status value={playerPreset.status} /></td>
                  <td>
                    <InUseLink
                      path={editPath({ applicationId: query.applicationId, ...playerPreset.presetFrontendRecord })}
                      {...playerPreset.presetFrontendRecord}
                    />
                  </td>
                  <td>
                    <InUseLink
                      path={editPath({
                        applicationId: query.applicationId,
                        query: { configurationSegmentId: playerPreset.configurationSegmentId },
                        ...playerPreset.configurationFrontendRecord,
                      })}
                      {...playerPreset.configurationFrontendRecord}
                    />
                  </td>
                  <td>{playerPreset.segmentId}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}
