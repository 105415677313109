import React from 'react';
import { Link } from 'react-router-dom';

export default function InUseLink({
  id, name, path, children, newTab = true, entityTypeKey = false,
}) {
  const text = children || name;
  return path ? (
    <Link to={path} key={`${entityTypeKey}_${id}`} target={newTab && '_blank'}>
      {text}
    </Link>
  ) : <span>{text}</span>;
}
