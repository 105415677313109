import { useOptionsQuery } from '@hooks';
import { PaymentsRoutes } from '@pages/routes';

export default function useStoreProductsOptions() {
  return useOptionsQuery({
    name: 'payments/storeProducts',
    request: PaymentsRoutes.StoreProducts.listOptionsRequest,
    transform: (data) => data,
  });
}
