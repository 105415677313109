import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import {
  Accordion, Button as BsButton, Col, Row,
} from 'react-bootstrap';
import { FormGroup, useFormGroup } from '@tripledotstudios/react-core';

import { DragAndDroppable, DragContext, DragHandle } from '@components/dnd';
import { BarsIcon } from '@components/icons';
import { SwitchSimple, hasError } from '@components/resource';
import { Button } from '@components/index';
import { useFormPermissions, useSortableFieldArray } from '@hooks';

const AccordionItem = styled(Accordion.Item)`
  border-top: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
`;

const DraggableHandle = ({ attributes, listeners }) => (
  <DragHandle attributes={{ ...attributes, className: 'mt-3' }} listeners={listeners}>
    <BarsIcon />
  </DragHandle>
);

// This function copies a record and clears internal ids. It saves us from errors like the following:
// Couldn't find Events::ConfigurationObjective with ID=1020 for Events::ActivitySetIteration with ID=
function copyRecord(record) {
  const { id, key, ...values } = record;

  Object.keys(values).forEach((attribute) => {
    if (!attribute.endsWith('Attributes')) return;

    if (Array.isArray(record[attribute])) {
      values[attribute] = record[attribute].map((attrs) => copyRecord(attrs));
    } else {
      values[attribute] = copyRecord(record[attribute]);
    }
  });

  return values;
}

export default function NestedRecordsList({
  attributesPath, entityName, bodyContent, headerContent, buildNewItem, addDisabled, reorderDisabled,
}) {
  const { readOnly } = useFormPermissions();
  const { generateName } = useFormGroup();
  const { getValues, watch } = useFormContext();
  const [dndEnabled, setDndEnabled] = useState(false);
  const formGroupName = generateName(attributesPath);
  const [activeKeys, setActiveKeys] = useState([]);
  const [appendedItemPosition, setAppendedItemPosition] = useState(null);

  const {
    fields,
    append,
    handleDelete,
    handleDragEnd,
    getNextPosition,
    resetPositions,
  } = useSortableFieldArray({ name: formGroupName });
  const fieldsWatch = watch(formGroupName);
  const controlledFields = fields.map((field, i) => ({ ...field, ...fieldsWatch[i], key: field.key }));

  const persistedFields = controlledFields.filter((f) => !f._destroy);
  useEffect(() => {
    if (persistedFields.length > 0) return;

    resetPositions();
  }, [persistedFields.length > 0]);

  useEffect(() => {
    const appendedItem = controlledFields[appendedItemPosition - 1];
    if (appendedItem) {
      setActiveKeys((keys) => [...keys, appendedItem.key]);
      setAppendedItemPosition(null);
    }
  }, [appendedItemPosition]);

  const appendItem = () => {
    const position = append({ ...buildNewItem() });
    setAppendedItemPosition(position);
  };

  const dndItems = controlledFields.filter(({ _destroy }) => !_destroy).map((field) => field.key);

  return (
    <>
      {!reorderDisabled && (
        <Row>
          <Col>
            <SwitchSimple
              className="float-start mb-2"
              checked={dndEnabled}
              value="1"
              label="Reorder"
              onChange={(e) => setDndEnabled(e.currentTarget.checked)}
            />
          </Col>
        </Row>
      )}
      <DragContext onDragEnd={handleDragEnd} items={dndItems}>
        <Accordion alwaysOpen activeKey={activeKeys} onSelect={setActiveKeys}>
          {controlledFields.map((field, index) => {
            if (field._destroy) { return null; }

            const itemPath = `${formGroupName}.${index}`;
            const duplicateItem = () => {
              const position = getNextPosition();
              append({ ...copyRecord(getValues(itemPath)), position });
              setAppendedItemPosition(position);
            };
            const deleteItem = () => { handleDelete(field); };
            return (
              <DragAndDroppable
                tag="div"
                id={field.key}
                key={field.key}
                disabled={!dndEnabled}
                dragHandleRender={dndEnabled ? DraggableHandle : null}
                className="d-flex align-items-start"
              >
                <FormGroup name={itemPath} key={`${field.key}-${field.position}`}>
                  <div className="d-flex flex-grow-1 ms-1">
                    <AccordionItem
                      eventKey={field.key}
                      className={`w-100 ${hasError(itemPath) && 'border-danger'}`}
                    >
                      <Accordion.Header>
                        {headerContent({ item: field })}
                      </Accordion.Header>
                      <Accordion.Body>
                        {bodyContent({ item: field })}
                        <div className="mt-2 d-flex justify-content-end">
                          <BsButton className="me-2" variant="primary" onClick={duplicateItem}>
                            Duplicate
                          </BsButton>
                          <BsButton variant="danger" onClick={deleteItem}>
                            Delete
                          </BsButton>
                        </div>
                      </Accordion.Body>
                    </AccordionItem>
                  </div>
                </FormGroup>
              </DragAndDroppable>
            );
          })}
        </Accordion>
      </DragContext>
      {!readOnly && (
        <Button.Add title={`Add ${entityName}`} className="mt-2" onClick={appendItem} disabled={addDisabled} />
      )}
    </>
  );
}
